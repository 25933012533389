export { default as strings } from './strings'
export { default as FirebaseConfig, vapidKey } from './firebaseConfig'
export { default as CountryCodes } from './CountryCodes'

export const apiSizeCount = 10
export const googleMapKey = 'AIzaSyAvMq-nF1pfUUgkzc0u_W_Fb_w2a7xF-6Y'

export * from './filters'

export const enquiryStatus = {
	created: 'CREATED',
	unverified: 'UNVERIFIED',
	active: 'ACTIVE',
	orderPlaced: 'ORDER_PLACED',
	expired: 'EXPIRED',
	cancelled: 'CANCELLED',
	deleted: 'DELETED',
}

export const auctionStatus = {
	created: 'CREATED',
	unverified: 'UNVERIFIED',
	active: 'ACTIVE',
	orderPlaced: 'ORDER_PLACED',
	expired: 'EXPIRED',
	cancelled: 'CANCELLED',
	deleted: 'DELETED',
	completed: 'COMPLETED',
	shortclosed: 'SHORTCLOSED',
	opening: 'OPENING',
	closing: 'CLOSING',
}

export const orderStatus = {
	orderPlaced: 'ORDER_PLACED',
	// active: 'ACTIVE',
	cancelled: 'CANCELLED',
	completed: 'COMPLETED',
	shortclosed: 'SHORT_CLOSED',
}

export const poStatus = {
	all: 'ALL',
	active: 'ACTIVE',
	cancelled: 'CANCELLED',
	completed: 'COMPLETED',
	shortclosed: 'SHORT_CLOSED',
}

export const brands = [
	'TATA',
	'SAIL',
	'JSPL',
	'RINL',
	'JSW',
	// 'ISI',
	// 'ROLLING',
	'APOLLO',
	'MTC',
	'ASIAN',
	'JAI BALAJI (Fe500D)',
	'RUNGTA 500',
	'ELECTRO STEEL',
	'KAMDHENU',
	'PRAKASH SURYA',
	'AMBA',
	'AFSTAR/MS AGARWAL',
	'RASHMI',
	'BHUSAN',
	'SHYAM STEEL',
	'Others',
	'NALCO',
]

export const documentInvoiceType = { tc: 'TC', bilti: 'LORRY', dkt: 'WEIGHMENT', ewaybill: 'EWAY', delivery_receipt: 'POD' }

export const DRAWER_TYPES = {
	ADD_NEW_ENQUIRY_ITEM: 'ADD_NEW_ENQUIRY_ITEM',
	EDIT_ENQUIRY_ITEM: 'EDIT_ENQUIRY_ITEM',
	ADD_NEW_ADDRESS: 'ADD_NEW_ADDRESS',
	VIEW_ENQUIRY: 'VIEW_ENQUIRY',
	VIEW_OPEN_ENQUIRY: 'VIEW_OPEN_ENQUIRY',
	ADD_INVENTORY: 'ADD_INVENTORY',
	VIEW_ORDER_INVOICE: 'VIEW_ORDER_INVOICE',
	EDIT_PERSONAL_DETAILS: 'EDIT_PERSONAL_DETAILS',
	EDIT_ORG_DETAILS: 'EDIT_ORG_DETAILS',
	ADMIN_ASSIGN_ENQUIRY: 'ADMIN_ASSIGN_ENQUIRY',
	OPEN_SEARCH_PANEL: 'OPEN_SEARCH_PANEL',
	EDIT_SUPPLIER_QUOTE: 'EDIT_SUPPLIER_QUOTE',
	ADD_SUPPLIER_QUOTE: 'ADD_SUPPLIER_QUOTE',
	SELECT_SUPPLIER_SCRAP: 'SELECT_SUPPLIER_SCRAP',
	ADD_CATEGORY: 'ADD_CATEGORY',
	EDIT_CATEGORY: 'EDIT_CATEGORY',
	ADD_BRAND: 'ADD_BRAND',
	ADD_COMMODITY: 'ADD_COMMODITY',
	EDIT_COMMODITY: 'EDIT_COMMODITY',
	ADD_PRODUCT: 'ADD_PRODUCT',
	EDIT_PRODUCT: 'EDIT_PRODUCT',
	ADMIN_EDIT_ORDER_INVOICE: 'ADMIN_EDIT_ORDER_INVOICE',
	ADMIN_ADD_INVENTORY: 'ADMIN_ADD_INVENTORY',
	ADMIN_ADD_BANNER: 'ADMIN_ADD_BANNER',
	ADMIN_EDIT_BANNER: 'ADMIN_EDIT_BANNER',
	ADMIN_ADD_NEWS: 'ADMIN_ADD_NEWS',
	ADMIN_EDIT_NEWS: 'ADMIN_EDIT_NEWS',
	SHOW_NEWS: 'SHOW_NEWS',
	VIEW_ORDER_SUMMARY: 'VIEW_ORDER_SUMMARY',
	ADMIN_VIEW_ORDER_SUMMARY: 'ADMIN_VIEW_ORDER_SUMMARY',
	ADMIN_FETCH_BILL: 'ADMIN_FETCH_BILL',
	ADMIN_ASSIGN_AUCTION: 'ADMIN_ASSIGN_AUCTION',
	SELECT_ADDRESS_FROM_ADDRESSBOOK: 'SELECT_ADDRESS_FROM_ADDRESSBOOK',
	ADMIN_VIEW_AUCTION_ORDER_SUMMARY: 'ADMIN_VIEW_AUCTION_ORDER_SUMMARY',
	HANDLE_AUCTION_QUOTE: 'HANDLE_AUCTION_QUOTE',
	ADD_LEDGERS: 'ADD_LEDGERS',
	ADD_COLLECTION: 'ADD_COLLECTION',
	COLLECTION_JOURNEY: 'COLLECTION_JOURNEY',
	ADMIN_USER_DASHBOARD_VIEW: 'ADMIN_USER_DASHBOARD_VIEW',
	ADD_NEW_LOGISTICS_ITEM: 'ADD_NEW_LOGISTICS_ITEM',
	EDIT_LOGISTICS_ITEM: 'EDIT_LOGISTICS_ITEM',
	VIEW_OPEN_LOGISTICS: 'VIEW_OPEN_LOGISTICS',
	OPEN_SEARCH_LOGISTICS: 'OPEN_SEARCH_LOGISTICS',
	ADD_NEW_LOGISTICS_QUOTES: 'ADD_NEW_LOGISTICS_QUOTES',
	EDIT_LOGISTICS_QUOTES: 'EDIT_LOGISTICS_QUOTES',
	LOGISTICS_PARTNER_ADD_QUOTES: 'LOGISTICS_PARTNER_ADD_QUOTES',
	LOGISTICS_PARTNER_EDIT_QUOTES: 'LOGISTICS_PARTNER_EDIT_QUOTES',
	ADMIN_LOGISTICS_ORDER_INVOICE: 'ADMIN_LOGISTICS_ORDER_INVOICE',
	ADMIN_SHARE_ENQUIRY: 'ADMIN_SHARE_ENQUIRY',
	ADD_ORG_REMARKS: 'ADD_ORG_REMARKS',
	CLOUD_FACTORY_SEARCH: 'CLOUD_FACTORY_SEARCH',
	CLOUD_ORDER_INVENTORY_ITEMS: 'CLOUD_ORDER_INVENTORY_ITEMS',
	CLOUD_ORDER_ENQUIRY_ITEMS: 'CLOUD_ORDER_ENQUIRY_ITEMS',
	ADMIN_EDIT_FABRICATION_INVOICE: 'ADMIN_EDIT_FABRICATION_INVOICE',
	EDIT_FABRICATION_INVOICE: 'EDIT_FABRICATION_INVOICE',
	MANAGE_FACTORY_ORDER_ITEM_JOURNEY: 'MANAGE_FACTORY_ORDER_ITEM_JOURNEY',
	MANAGE_ORDER_WASTE_ITEM: 'MANAGE_ORDER_WASTE_ITEM',
	CLOSING_REASON_ENQUIRY: 'CLOSING_REASON_ENQUIRY',
	LOGISTICS_ADD_PRICE: 'LOGISTICS_ADD_PRICE',
	ADMIN_FREIGHT_CALCULATOR: 'ADMIN_FREIGHT_CALCULATOR',
	FREIGHT_CALCULATOR: 'FREIGHT_CALCULATOR',
	ADMIN_INVOICES: 'ADMIN_INVOICES',
	ADD_UNDERWRITING: 'ADD_UNDERWRITING',
	UNDERWRITING_STATUS: 'UNDERWRITING_STATUS',
	UNDERWRITING_PAST_REPORT_STATUS: 'UNDERWRITING_PAST_REPORT_STATUS',
	UNDERWRITING_SEARCH: 'UNDERWRITING_SEARCH',
	SELES_TEAM_FEEDBACK: 'SELES_TEAM_FEEDBACK',
	FINANCE_TEAM_FEEDBACK: 'FINANCE_TEAM_FEEDBACK',
	CROSS_DIRECTOR_LIST: 'CROSS_DIRECTOR_LIST',

	COMPLETE_YOUR_KYC: 'COMPLETE_YOUR_KYC',
	ADD_COMMODITY_PRICES: 'ADD_COMMODITY_PRICES',
	REQUEST_CREDIT: 'REQUEST_CREDIT',

	NOTIFICATIONS: 'NOTIFICATIONS',
	ADD_ORG_ADDRESS: 'ADD_ORG_ADDRESS',
	ADD_ORG_GST: 'ADD_ORG_GST',
	ADD_ORG_ROLE: 'ADD_ORG_ROLE',
	ADD_USER_COMMODITY: 'ADD_USER_COMMODITY',
	EDIT_ORGANISATION_DETAILS: 'EDIT_ORGANISATION_DETAILS',
	EDIT_ORGANISATION_PAN: 'EDIT_ORGANISATION_PAN',
	EDIT_ORGANISATION_NAME: 'EDIT_ORGANISATION_NAME',
	EDIT_USER_DETAILS: 'EDIT_USER_DETAILS',
	ADD_NEW_USER: 'ADD_NEW_USER',
	COMMODITY_CATEGORY_LIST: 'COMMODITY_CATEGORY_LIST',
	ADD_NEW_ORDER_ITEM: 'ADD_NEW_ORDER_ITEM',
	ORG_SERVICES: 'ORG_SERVICES',

	ADD_NEW_ORGANISATION: 'ADD_NEW_ORGANISATION',
	MERGE_ORGANISATION: 'MERGE_ORGANISATION',
	ADMIN_GENERATE_ESTIMATE: 'ADMIN_GENERATE_ESTIMATE',
	CHOOSE_PHOTO: 'CHOOSE_PHOTO',
	MANAGE_ENQUIRY_JOURNEY: 'MANAGE_ENQUIRY_JOURNEY',
	MANAGE_ORDER_JOURNEY: 'MANAGE_ORDER_JOURNEY',
	ADD_LEAD: 'ADD_LEAD',
	EDIT_TASKS: 'EDIT_TASKS',
	LEAD_COMPLETE: 'LEAD_COMPLETE',
	LEAD_JOURNEY: 'LEAD_JOURNEY',
	EDIT_LEAD_MANAGER: 'EDIT_LEAD_MANAGER',
	ADMIN_MONTHLY_TARGET: 'ADMIN_MONTHLY_TARGET',
	DELETE_ADMIN: 'DELETE_ADMIN',
	ADD_BANK_ACCOUNT: 'ADD_BANK_ACCOUNT',
	ADD_PDC: 'ADD_PDC',
	EDIT_PDC: 'EDIT_PDC',
	BUSINESS_DETAILS: 'BUSINESS_DETAILS',
	ADHOC_EDIT_INSURER_CREDIT: 'ADHOC_EDIT_INSURER_CREDIT',
	FINANCIAL_REMARK: 'FINANCIAL_REMARK',
	FINANCIAL_ORG_BANK: 'FINANCIAL_ORG_BANK',
	FINANCIAL_ORG_SECURITY: 'FINANCIAL_ORG_SECURITY',
	FINANCIAL_ORG_PAYMENT_MODE: 'FINANCIAL_ORG_PAYMENT_MODE',
	ADD_CREDIT_LIMIT: 'ADD_CREDIT_LIMIT',
	DATA_CORRECTION_TRADING_EXPENSE: 'DATA_CORRECTION_TRADING_EXPENSE',
	ADD_BLOG_FAQ: 'ADD_BLOG_FAQ',
	ADD_FINANCIAL_DOC: 'ADD_FINANCIAL_DOC',
	ORG_INCORPORATION_DATE: 'ORG_INCORPORATION_DATE',
}

export const MODAL_TYPES = {
	CONFIRMATION: 'CONFIRMATION',
}

export const becomeRequestRole = {
	manufacturer_Request: 'manufacturer_request',
	supplier_Request: 'supplier_request',
	logistics_Request: 'logistics_request',
	fabricator_Request: 'fabricator_request',
	scrap_Request: 'scrap_request',
}

export const becomeRequestRoleAPIKeys = {
	[becomeRequestRole.manufacturer_Request]: {
		apiKey: 'manufacturer_rights_requested',
		buttonTextKey: 'become_a_manufacturer_partner',
		descriptionKey: 'become_a_manufacturer_partner_description',
		pendingRequestKey: 'become_a_manufacturer_partner_request_pending',
	},
	[becomeRequestRole.supplier_Request]: {
		apiKey: 'supplier_rights_requested',
		buttonTextKey: 'become_a_supplier_partner',
		descriptionKey: 'become_a_supplier_partner_description',
		pendingRequestKey: 'become_a_supplier_partner_request_pending',
	},
	[becomeRequestRole.logistics_Request]: {
		apiKey: 'logistics_rights_requested',
		buttonTextKey: 'become_a_logistics_partner',
		descriptionKey: 'become_a_logistics_partner_description',
		pendingRequestKey: 'become_a_logistics_partner_request_pending',
	},
	[becomeRequestRole.fabricator_Request]: {
		apiKey: 'fabricator_rights_requested',
		buttonTextKey: 'become_a_proccessing_partner',
		descriptionKey: 'become_a_proccessing_partner_description',
		pendingRequestKey: 'become_a_proccessing_partner_request_pending',
	},
	[becomeRequestRole.scrap_Request]: {
		apiKey: 'supplier_rights_requested',
		buttonTextKey: 'become_a_scrap_partner',
		descriptionKey: 'become_a_scrap_partner_description',
		pendingRequestKey: 'become_a_scrap_partner_request_pending',
	},
}

export const logisticsStatus = {
	active: 'ACTIVE',
	cancelled: 'CANCELLED',
	completed: 'COMPLETED',
	created: 'CREATED',
	expired: 'EXPIRED',
	orderPlaced: 'ORDER_PLACED',
	shortclosed: 'SHORT_CLOSED',
	unverified: 'UNVERIFIED',
}

export const logisticsOrderStatus = {
	active: 'ACTIVE',
	cancelled: 'CANCELLED',
	orderPlaced: 'ORDER_PLACED',
	completed: 'COMPLETED',
}

export const cookieKeys = {
	firebaseUser: 'mb-auth-user',
	user: 'mb-user',
	language: 'mb-language',
}

export const logisticDocType = {
	lorry: 'lorry',
	dispatch: 'dispatch',
	drop: 'drop',
	material: 'material',
}

export const fireBaseError = {
	invalidPhoneNumber: 'auth/invalid-phone-number',
	tooManyRequests: 'auth/too-many-requests',
	maximumUserCountExceeded: 'auth/maximum-user-count-exceeded',
	invalidVerificationCode: 'auth/invalid-verification-code',
	recent_login_reqd: 'auth/requires-recent-login',
	email_exists: 'auth/email-already-exists',
	email_already_use: 'auth/email-already-in-use',
	user_not_found: 'auth/user-not-found',
	wrong_password: 'auth/wrong-password',
}

export const rejectionReason = {
	L1: 'L1',
	brand: 'BRAND',
	payment: 'PAYMENT',
	buyer: 'BUYER',
	logistic: 'LOGISTICS',
	availability: 'AVAILABILITY',
	requirement_postponed: 'REQ POSTPONED',
}

export const getRejectionReasonDetail = (reason) => {
	switch (reason) {
		case 'L1':
			return 'Quote not L1'
		case 'BRAND':
			return 'Brand Mismatch'
		case 'PAYMENT':
			return 'Payment terms issue'
		case 'BUYER':
			return 'Order not confirmed'
		case 'AVAILABILITY':
			return 'Material availability'
		case 'REQ POSTPONED':
			return 'Req. postponed'
		case 'LOGISTICS':
			return 'Logistics'
		default:
			break
	}
}

export const closingReason = {
	retailer: 'Retail Enquiry',
	feasible_qty: 'Insufficient quantity',
	no_gst: 'Required documents(GST) not available',
	call_connected: 'Unable to connect with client',
	quote_not_accepted: 'Quote not accepted',
	logistic_quote: 'Logistic Quote',
	payment_terms: 'Payment Terms',
	supplier: 'Supplier',
	buyer_closed: 'Buyer closed the requirement',
	better_quotation: 'Got better quotation',
	requirement_postponed: 'Requirement postponed',
	no_quotes: "Couldn't get quotes",
	material_unavailabilty: 'Material unavailibity',
	invalid: 'ENQUIRY INVALID',
}

export const MACHINE_TYPES = [
	{
		label: 'Manual',
		key: 'MANUAL',
	},
	{
		label: 'Semi Automatic',
		key: 'SEMI_AUTOMATIC',
	},
	{
		label: 'Automatic',
		key: 'AUTOMATIC',
	},
	{
		label: 'Laser',
		key: 'LASER',
	},
	{
		label: 'Gas Cutter',
		key: 'GAS CUTTER',
	},
]

export const PROCESS_TYPES = [
	{
		label: 'Cut to length',
		key: 'CUT TO LENGTH',
	},
	{
		label: 'Slitting',
		key: 'SLITTING',
	},
	{
		label: 'Cut and Bend',
		key: 'CUT AND BEND',
	},
	{
		label: 'Shearing',
		key: 'SHEARING',
	},
	{
		label: 'Pickling and Oiling',
		key: 'PICKLING AND OILING',
	},
]

export const downloadSampleFile = {
	USER_SAMPLE: 'sample/user_sample.csv',
}

export const CLOUD_FACTORY_SLIDE_COMPONENT_TYPE = {
	ADD_MACHINE: 'ADD_MACHINE',
	EDIT_MACHINE: 'EDIT_MACHINE',
	ADD_INVENTORY: 'ADD_INVENTORY',
	EDIT_INVENTORY: 'EDIT_INVENTORY',
}

export const InventoryTypes = {
	STANDARD: 'STANDARD',
	NON_STANDARD: 'NON_STANDARD',
	SCRAP: 'SCRAP',
}

export const InventoryTypesList = [
	{
		id: InventoryTypes.STANDARD,
		value: 'STANDARD',
	},
	{
		id: InventoryTypes.NON_STANDARD,
		value: 'NON STANDARD',
	},
	{
		id: InventoryTypes.SCRAP,
		value: 'SCRAP',
	},
]

export const cloudOrderStatus = {
	created: 'ORDER_PLACED',
	// orderProgress: 'ORDER_IN_PROGRESS',
	orderCompleted: 'ORDER_COMPLETED',
	cancelled: 'CANCELLED',
	deleted: 'DELETED',
}

export const transactionSourceStatus = {
	deposit: 'DEPOSIT',
	invoice: 'INVOICE',
	credit_note: 'CREDIT_NOTE',
}

export const transactionTypeStatus = {
	debit: 'DEBIT',
	credit: 'CREDIT',
}

export const languages = {
	english: 'en',
	hindi: 'hi',
	bengali: 'be',
}

export const CHAT_STATUS = {
	LOADING: 'LOADING',
	ERROR: 'ERROR',
	NO_MESSAGE: 'NO_MESSAGE',
	SUCCESS: 'SUCCESS',
}

export const ScrapSteps = {
	STEP_ONE: 1,
	STEP_TWO: 2,
	STEP_THREE: 3,
	STEP_FOUR: 4,
	STEP_FIVE: 5,
}

export const NOTIFICATION_TYPES = ['email', 'platform', 'sms', 'whatsapp']
export const paymentTenure = [
	{ dayName: '7 Days' },
	{ dayName: '15 Days' },
	{ dayName: '30 Days' },
	{ dayName: '40 Days' },
	{ dayName: '45 Days' },
	{ dayName: '60 Days' },
	{ dayName: '90 Days' },
]
export const preferredPaymentTerms = { advance: 'ADVANCE', pod: 'POD', credit: 'CREDIT', lc: 'LC' }

export const ORG_USER_ROLES = {
	ORG_ADMIN: 'ORG_ADMIN',
	ORG_MANAGER: 'ORG_MANAGER',
}

export const roles = {
	buyer: 'BUYER',
	trader: 'SUPPLIER',
	manufacturer: 'MANUFACTURER',
	fabricator: 'CLOUD_FACTORY',
	logistic: 'LOGISTICS',

	admin: 'ADMIN',
	manager: 'MANAGER',
	tools_manager: 'TOOLS_MANAGER',
	// staff: 'STAFF',
}

export const ORG_ROLES = {
	marketplaceBuyer: { role: 'BUYER', platform: 'MARKETPLACE' },
	marketplaceSupplier: { role: 'SUPPLIER', platform: 'MARKETPLACE' },
	scrapSupplier: { role: 'SUPPLIER', platform: 'SCRAP' },
	scrapBuyer: { role: 'BUYER', platform: 'SCRAP' },
	cloudSupplier: { role: 'SUPPLIER', platform: 'CLOUD_FACTORY' },
}

export const MB_ROLES = {
	SUPER_ADMIN: 'SUPER_ADMIN',

	SALES: 'SALES_ADMIN',
	SUPPLY: 'SUPPLY_ADMIN',

	TOOLS: 'TOOL_ADMIN',
	FINANCE: 'FINANCE_ADMIN',
	ADMIN: 'ADMIN', // implementation
}

export const REGION_TYPES = {
	NORTH: 'NORTH',
	EAST: 'EAST',
	WEST: 'WEST',
	SOUTH: 'SOUTH',
	CENTRAL: 'CENTRAL',
	EXPORT: 'EXPORT',
}
export const SUBROLE_TYPES = {
	PORTAL: 'PORTAL',
	ACCOUNTS: 'ACCOUNTS',
	MANAGEMENT: 'MANAGEMENT',
	PURCHASE: 'PURCHASE',
}

export const docTypeAll = [
	{ name: 'MOOOK_INVOICE', aliasname: 'Mbook Invoice' },
	{ name: 'MBOOK_INVOICE_CUSTOM_CERTIFIED', aliasname: 'Mbook Invoice Custom Certified' },
	{ name: 'INSURANCE', aliasname: 'Insurance' },
	{ name: 'PACKING_LIST_CUSTOM_CERTIFIED', aliasname: 'Packing List (Custom Certified)' },
	{ name: 'LAST_MILE', aliasname: 'Last Mile' },
	{ name: 'COUNTRY_OF_ORIGIN_CERTIFICATE_COO', aliasname: 'Country Of Origin Certificate (COO)' },
	{ name: 'INSPECTION_CERTIFICATE', aliasname: 'Inspection Certificate' },
	{ name: 'ECGC_INSURANCE', aliasname: 'ECGC Insurance' },
	{ name: 'PACKING_LIST', aliasname: 'Packing List' },
	{ name: 'FREIGHT_FORWARD_CHECKLIST', aliasname: 'Freight Forward Checklist' },
	{ name: 'BILL_OF_LADING', aliasname: 'Bill Of Lading' },
	{ name: 'LETTER_OF_CREDIT', aliasname: 'Letter Of Credit' },
	{ name: 'BILL_OF_EXCHANGE', aliasname: 'Bill Of Exchange' },
	{ name: 'CHA_FEE_RECEIPT', aliasname: 'CHA Fee Receipt' },
	{ name: 'BANK_REALISATION_CERTIFICATE', aliasname: 'Bank Realisation Certificate' },
	{ name: 'OTHER', aliasname: 'Other' },
]

export const enquiryPlatform = [{ name: 'MARKETPLACE' }, { name: 'SCRAP' }, { name: 'CLOUD_FACTORY' }]

export const zohoStatusFilter = [{ name: 'PAID' }, { name: 'DUE' }, { name: 'OVERDUE' }]

export const pastRecordTypes = { LAST_ORDER: 'LAST_ORDER', THIS_YEAR: 'THIS_YEAR', OVER_ALL: 'OVERALL' }

export const supplyUserType = [{ name: 'SELF' }, { name: 'OVERALL' }]
export const monthType = [{ month: 1 }, { month: 3 }, { month: 6 }, { month: 12 }, { month: 'OVERALL' }]

export const transactionTypeDuePayments = { purchase: 'PURCHASE' }

export const modeOfPayment = {
	CHEQUE: 'CHEQUE',
	DIRECT_BANK_TRANSFER: 'DIRECT BANK TRANSFER',
	LC_DISCOUNTING: 'LC DISCOUNTING',
	INVOICEMART: 'INVOICEMART',
	RXIL: 'RXIL',
	ATREDS: 'ATREDS',
	M1XCHANGE: 'M1XCHANGE',
	OTHERS: 'OTHERS',
}

export const ISSUE_TYPE = {
	INTENTION: 'INTENTION',
	ABILITY: 'ABILITY',
}

export const ABILITY_ISSUE_REASON_OF_DELAY = {
	QUALITY_ISSUE: 'Quality/make issue',
	PROJECT_DELAYED_OR_DEREFFERED: 'Project delayed/deferred',
	PAYMENT_STUCK_WITH_GOVT: 'payment stuck with goverment deparment',
	OTHER_ISSUE: 'Any other issue',
}

export const ABILITY_ISSUE = {
	NO_OTHER_SOURCE: 'Customer payment stuck where no other source of payment available',
	OTHER_SOURCE_PRESENT: 'Customer payment stuck where other source of payment available',
	OTHER: 'Other',
}

export const INTENTION_ISSUE = {
	CUSTOMER_NO_RESPONSE: 'Customer not responding',
	CUSTOMER_EXTENDING_TIMELINES: 'Customer showing timelines without reasons',
	CUSTOMER_HOLDING_PDC: 'Customer asking to hold PDCs',
	OTHER: 'Other',
}

export const COLLECTION_STATUS = {
	PENDING: 'PENDING',
	CANCELLED: 'CANCELLED',
	PAID: 'PAID',
	PARTIALLY_PAID: 'PARTIALLY PAID',
	RESCHEDULED: 'RESCHEDULED',
}

export const onlineDiscountingSubtypes = { INVOICEMART: 'INVOICEMART', RXIL: 'RXIL', ATREDS: 'ATREDS', M1XCHANGE: 'M1XCHANGE', OTHERS: 'OTHERS' }

export const LEADS_TASK_TYPE = { COLD_CALL: 'COLD CALL', MEETING: 'MEETING', FOLLOW_UP: 'FOLLOW UP' }

export const LEADS_SOURCE = {
	GOOGLE: 'GOOGLE',
	FACEBOOK: 'FACEBOOK',
	LINKEDIN: 'LINKEDIN',
	EXHIBITION: 'EXHIBITION',
	COLD_CALLING: 'COLD CALLING',
	WEBSITE: 'WEBSITE',
	REFERRAL: 'REFERRAL',
	WHATSAPP: 'WHATSAPP',
	GOVT_TENDER_DATABASE: 'GOVT. TENDER DATABASE',
}

export const LEADS_BUSINESS_CATEGORY = {
	ECOM: 'ECOM',
	CLOUD_FACTORY: 'CLOUD_FACTORY',
	RECYCLE_BUSINESS: 'RECYCLING BUSINESS-MS',
	NON_FERROUS: 'NON-FERROUS',
	OTHERS: 'OTHERS',
	BITUMEN: 'BITUMEN',
	EXPORT: 'EXPORT',
}

export const LEADS_INDUSTRY = {
	INFRASTRUCTURE: 'INFRASTRUCTURE',
	REAL_ESTATE: 'REAL ESTATE',
	AUTOMOBILE: 'AUTOMOBILE',
	MANUFACTURING: 'MANUFACTURING',
	REFINERY: 'REFINERY',
}

export const TASK_PRIORITY = {
	LOW: 'LOW',
	MEDIUM: 'MEDIUM',
	HIGH: 'HIGH',
}

export const MEETING_MODE = {
	ONLINE: 'ONLINE',
	OFFLINE: 'OFFLINE',
}

export const LEADS_STATUS = {
	OPEN: 'OPEN',
	COLD_CALL: 'COLD CALL',
	MEETING: 'NEW MEETING',
	FOLLOW_UP: 'FOLLOW UP MEETING',
	WON: 'WON',
	LOST: 'LOST',
}

export const enquiryInputType = {
	WEIGHT: 'WEIGHT',
	PIECE: 'PIECE',
	LENGTH: 'LENGTH',
}

export const categoryCustomisations = {
	CTL: 'Cut to Length (CTL)',
	Slitting: 'Slitting',
	Shearing: 'Shearing',
	Oiling_Pickling: 'Oiling & Pickling',
	CNC_Cutting: 'CNC Cutting',
	CAB: 'Cut & Bend (CAB)',
}

export const LOGISTICS_HANDLER_TYPES = {
	BUYER: 'BUYER',
	SUPPLIER: 'SUPPLIER',
	MBOOK: 'MBOOK',
}

export const LEAD_TYPE = {
	BUYING: 'BUYING',
	SELLING: 'SELLING',
	FINANCE: 'FINANCE',
}

export const CONTENT_TYPE = {
	BLOG: 'BLOG',
	NEWS: 'NEWS',
	MEDIA_COVERAGE: 'MEDIA_COVERAGE',
}

export const FINAL_LEAD_STATUS = { WON: 'WON', LOST: 'LOST' }

export const CONTENT_TAGS = [
	'STEEL',
	'SUPPLY CHAIN',
	'TECHNOLOGY',
	'DIGITISATION',
	'RECYCLING',
	'FINANCE',
	'MARKETPLACE',
	'METAL FABRICATION',
	'METAL PROCESS',
	'FERROUS',
	'NON-FERROUS',
	'CUSTOM MANUFACTURING',
	'INFRASTRUCTURE & CONSTRUCTION',
	'ELECTRIC VEHICLES',
	'RENEWABLE ENERGY',
	'AUTOMOTIVE & TRANSPORTATION',
	'DEFENSE & AEROSPACE',
	'MACHINERY EQUIPMENT',
	'OIL & GAS',
	'CUSTOMER DURABLES',
	'ENTERPRISE',
	'CASE STUDY',
	'TESTIMONIALS',
	'LOGISTICS',
	'METAL CUSTOMISATION',
	'PROCUREMENT',
	'FULFILMENT',
	'SUSTAINABILITY',
	'CREDIT',
	'NBFCs',
	'GREEN STEEL',
	'CARBON EMISSIONS',
]

export const CREDIT_LIMIT_STATUS = {
	REQUESTED: 'REQUESTED',
	ACTIVE: 'ACTIVE',
	IN_REVIEW: 'IN REVIEW',
	CLOSED: 'CLOSED',
	CANCELLED: 'CANCELLED',
	EXPIRED: 'EXPIRED',
}

export const CREDIT_LIMIT_TYPES = { ADHOC: 'ADHOC', ENHANCEMENT: 'ENHANCEMENT', BASE: 'BASE' }

export const FINANCE_FEEDBACK_TYPES = { SALES: 'SALES', SUPPLY: 'SUPPLY', FINANCE: 'FINANCE' }

export const OTHER_CHARGES_INVOICE = [
	{ key: 'freight_charge', label: 'Freight Charge' },
	{ key: 'cutting_charge', label: 'Cutting Charge' },
	{ key: 'pickling_charge', label: 'Pickling Charge' },
	{ key: 'insurance', label: 'Insurance' },
	{ key: 'packaging_charge', label: 'Packing Charges' },
	{ key: 'slitting_charge', label: 'Slitting Charge' },
	{ key: 'other_charge', label: ' Other Charge' },
	{ key: 'packing_charge', label: 'Packing Charge' },
	{ key: 'pmi_charge', label: 'PMI Charge' },
	{ key: 'transport_detention_charge', label: 'Transport detention Charge' },
]

export const GST_PERCENTAGE_VALUES = { 0: 0, 5: 5, 12: 12, 18: 18, 28: 28 }

export const MBOOK_BANK = [
	{
		account_id: '500637000024948036',
		account_name: '(ICICI)ESCROW- 777705052502',
		account_code: '777705052502',
		currency_code: 'INR',
		account_type: 'bank',
		account_number: '777705052502',
		ifsc: 'ICICO000399',
	},
	{
		account_id: '500637000000762083',
		account_name: 'Debtor/Creditor Clearing A/c',
		account_code: '',
		account_type: 'payment_clearing',
	},

	{
		account_id: '500637000000948777',
		account_name: 'HSBC',
		account_code: '',
		ifsc: 'HSBC0110005',
		account_number: 'xxxx8002',
		bank_name: 'HK and Shanghai Banking Corp Ltd',
	},

	{
		account_id: '500637000002538019',
		account_name: 'IDFC CA-5599',
		account_code: '',
		account_number: 'xxxx5599',
		bank_name: 'IDFC FIRST BANK',
		ifsc: 'IDFB0020101',
	},
	{
		account_id: '500637000002755098',
		account_name: 'IDFC CC-10102737749',
		account_code: '10102737749',
		account_number: '10102737749',
		bank_name: 'IDFC First Bank',
		ifsc: 'IDFB0020107',
	},
	{
		account_id: '500637000002755083',
		account_name: 'IDFC OD 10101209696',
		account_code: '10101209696',
		account_number: 'xxxx9696',
		bank_name: 'IDFC',
		ifsc: 'IDFB0020101',
	},
	{
		account_id: '500637000000188512',
		account_name: 'Kotak Mahindra Bank 7817',
		account_code: '',
		account_number: 'xxxx7817',
		bank_name: 'Kotak Mahindra Bank',
		ifsc: 'KKBK0000261',
	},
	{
		account_id: '500637000011122019',
		account_name: 'MBOOK ICICI BANK- 724005000281',
		account_code: '724005000281',
		account_number: 'xxxx0281',
		bank_name: 'ICICI BANK LTD',
		ifsc: 'ICIC0007240',
	},
	{
		account_id: '500637000014618391',
		account_name: 'MBOOK YES BANK-102581300000481',
		account_code: '',
		account_number: 'xxxx0481',
		bank_name: 'YES BANK ',
		ifsc: 'YESB0001025',
	},
	{
		account_id: '500637000003786636',
		account_name: 'SBM-CA/OD',
		account_code: '',
		account_number: 'xxxx0734',
		bank_name: 'SBM',
		ifsc: 'STCB0000065',
	},
]
